// store/modules/admin.ts
import { VuexModule, Module, Mutation, Action } from "vuex-module-decorators";
import API from "@/services/api/api";
import { AxiosRequestConfig } from "axios";
import DocAPI from "@/services/api/DocApi";
import i18n from "@/plugins/i18n";
import { Application, UpdateUsersStatus } from "@/types";

function initialState(): { [key: string]: any } {
  return {
    current: 0,
    totalLength: 0,
    updateUsersStatus: null,
    applications: []
  };
}

@Module({ namespaced: true })
export default class AdminModule extends VuexModule {
  [key: string]: any;
  //
  //  STATE
  //
  private current: number = initialState().current;
  private totalLength: number = initialState().totalLength;
  private updateUsersStatus: UpdateUsersStatus = initialState()
    .updateUsersStatus;
  private applications: Application[] = initialState().applications;
  //
  //  GETTERS
  //
  //
  //  ACTIONS
  //
  @Action
  public reset(): void {
    this.context.commit("RESET");
  }
  @Action
  public async fetchApplications() {
    const res = await API.post(
      "application/list",
      this.context.rootGetters["FiltersModule/getFilters"]
    );
    if (res) {
      this.context.commit("APPLICATIONS", res);
      return true;
    } else return false;
  }
  @Action
  public async downloadReport() {
    try {
      const link: any = await DocAPI.post(
        "application/list/download",
        this.context.rootGetters["FiltersModule/getFilters"]
      );
      link.download = i18n.t("pages.admin.options.report-file-name");
      document.body.appendChild(link);
      link.click();
      return true;
    } catch (e) {
      return false;
    }
  }
  @Action
  public async putRestrictionStatus(restricted: boolean) {
    try {
      const params = new URLSearchParams();
      params.append("active", String(restricted));
      const config: AxiosRequestConfig = { params };
      const res: any = await API.put(
        "application/create/restrict",
        null,
        config
      );
      return typeof res === "string" ? true : res;
    } catch {
      return false;
    }
  }
  @Action
  public async getRestrictionStatus() {
    try {
      return await API.get("application/create/restrict");
    } catch {
      return "error";
    }
  }
  @Action
  public async updateUsers(file: File) {
    try {
      const formData = new FormData();
      formData.append("file", file);
      const res: any = await API.post("user/update", formData);
      return !!res;
    } catch {
      return false;
    }
  }
  @Action
  public async getUpdateUserStatus() {
    try {
      const res: UpdateUsersStatus = await API.get("user/update/status");
      if (!res) return false;
      else {
        this.context.commit("UPDATE_USERS_STATUS", res);
        return true;
      }
    } catch (e) {
      return false;
    }
  }
  @Action
  public async setStatusPaid(ids: string[]) {
    try {
      const res: any = await API.put("application/batch/pay", ids);
      return !!res;
    } catch {
      return false;
    }
  }
  //
  //  MUTATIONS
  //
  @Mutation
  private RESET(): void {
    Object.keys(this).forEach((key: string) => {
      this[key] = initialState()[key];
    });
  }
  @Mutation
  public CURRENT(payload: number) {
    this.current = payload;
  }
  @Mutation
  public APPLICATIONS(payload: { applications: []; totalLength: number }) {
    this.applications = payload.applications;
    this.applications.forEach(
      ({ status }, index) =>
        (this.applications[index].isSelectable = status === "approved")
    );
    this.totalLength = payload.totalLength;
  }
  @Mutation
  public UPDATE_USERS_STATUS(payload: UpdateUsersStatus) {
    this.updateUsersStatus = payload;
  }
}
