import { RouteConfig } from "vue-router";

const routes: Array<RouteConfig> = [
  {
    path: "/",
    redirect: "/categories"
  },
  {
    path: "/auth",
    name: "Auth",
    components: {
      content: () => import("@/views/VAuth.vue"),
      appbar: () => import("@/components/Bars/BarAuth.vue")
    },
    meta: {
      public: true,
      title: "pages.auth.title"
    }
  },
  {
    path: "/categories",
    name: "Categories",
    components: {
      content: () => import("@/views/VMain.vue"),
      appbar: () => import("@/components/Bars/BarMain.vue")
    },
    meta: {
      title: "pages.categories.title",
      permission: "CATEGORIES",
      public: false
    }
  },
  {
    path: "/categories/:category",
    name: "Category",
    components: {
      content: () => import("@/views/VCategory.vue"),
      appbar: () => import("@/components/Bars/BarApp.vue")
    },
    meta: {
      title: "pages.category.title",
      permission: "CATEGORY",
      public: false
    },
    props: {
      content: true
    }
  },
  {
    path: "/item/:item",
    name: "Item",
    components: {
      content: () => import("@/views/VItem.vue"),
      appbar: () => import("@/components/Bars/BarApp.vue")
    },
    meta: {
      title: "pages.item.title",
      permission: "ITEM",
      public: false
    },
    props: {
      content: true
    }
  },
  {
    path: "/profile",
    name: "Profile",
    components: {
      content: () => import("@/views/VProfile.vue"),
      appbar: () => import("@/components/Bars/BarApp.vue")
    },
    meta: {
      title: "pages.profile.title",
      permission: "PROFILE",
      public: false
    }
  },
  {
    path: "/admin",
    name: "Admin",
    components: {
      content: () => import("@/views/VAdmin.vue"),
      appbar: () => import("@/components/Bars/BarApp.vue")
    },
    meta: {
      title: "pages.admin.title",
      permission: "ADMIN",
      public: false
    }
  },
  {
    path: "/application/:uid",
    name: "Application",
    components: {
      content: () => import("@/views/VApplication.vue"),
      appbar: () => import("@/components/Bars/BarApp.vue")
    },
    meta: {
      title: "pages.application.title",
      permission: "APPLICATION",
      public: false
    },
    props: {
      content: true
    }
  },
  {
    path: "/help",
    name: "Help",
    components: {
      appbar: () => import("@/components/Bars/BarApp.vue"),
      content: () => import("@/views/VHelp.vue")
    },
    meta: {
      title: "pages.help.title",
      permission: "HELP",
      public: false
    }
  },
  {
    path: "/404",
    name: "404",
    components: {
      content: () => import("@/views/VNotFound404.vue")
    },
    meta: {
      public: true,
      title: "pages.404.title"
    }
  },
  {
    path: "*",
    redirect: "/404"
  }
];
export default routes;
