const accessTokenKey = "at";
const refreshTokenKey = "rt";
const accessTokenExpiryKey = "ae";
const refreshTokenExpiryKey = "re";
const emailKey = "e";

function parse(v: string | null): any {
  if (v) return JSON.parse(v);
  else return;
}

class WebStorage {
  storage = localStorage;
  constructor() {
    if (typeof this.storage === "object") {
      try {
        this.storage.setItem("storage", "item");
        this.storage.removeItem("storage");
      } catch (e) {
        // //We're going to detect this and just silently drop any calls to setItem
        // //to avoid the entire page breaking, without having to do a check at each usage of Storage.
        // Storage.prototype._setItem = Storage.prototype.setItem;
        // Storage.prototype.setItem = function() {};

        alert(
          "Your web browser does not support storing settings locally. " +
            "Some settings may not save or some features may not work properly for you. " +
            'If you are using "Private Browsing Mode", ' +
            "you can disable it and try to access this website again."
        );
      }
    } else {
      alert(
        "Your web browser does not support storing settings locally. " +
          "Some settings may not save or some features may not work properly for you."
      );
    }
  }

  // GETTERS
  getAccessToken() {
    return parse(this.storage.getItem(accessTokenKey));
  }
  getRefreshToken() {
    return parse(this.storage.getItem(refreshTokenKey));
  }
  getAccessExpiry() {
    return parse(this.storage.getItem(accessTokenExpiryKey));
  }
  getRefreshExpiry() {
    return parse(this.storage.getItem(refreshTokenExpiryKey));
  }
  getEmail(): string {
    return parse(this.storage.getItem(emailKey));
  }

  // SETTERS
  setAccessToken(v: any): void {
    this.storage.setItem(accessTokenKey, JSON.stringify(v));
  }
  setRefreshToken(v: any): void {
    this.storage.setItem(refreshTokenKey, JSON.stringify(v));
  }
  setAccessTokenExpiry(v: any): void {
    this.storage.setItem(accessTokenExpiryKey, JSON.stringify(v));
  }
  setRefreshTokenExpiry(v: any): void {
    this.storage.setItem(refreshTokenExpiryKey, JSON.stringify(v));
  }
  setEmail(v: any): void {
    this.storage.setItem(emailKey, JSON.stringify(v));
  }

  // REMOVERS
  removeAccessToken(): void {
    this.storage.removeItem(accessTokenKey);
  }
  removeRefreshToken(): void {
    this.storage.removeItem(refreshTokenKey);
  }
  removeAccessExpiry(): void {
    this.storage.removeItem(accessTokenExpiryKey);
  }
  removeRefreshExpiry(): void {
    this.storage.removeItem(refreshTokenExpiryKey);
  }
  removeEmail(): void {
    this.storage.removeItem(emailKey);
  }
  clear(): void {
    this.removeAccessToken();
    this.removeRefreshToken();
    this.removeAccessExpiry();
    this.removeRefreshExpiry();
  }
}

export default new WebStorage();
