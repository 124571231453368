// store/modules/item.ts
import { VuexModule, Module, Mutation, Action } from "vuex-module-decorators";
import API from "@/services/api/api";
import { Application } from "@/types";
import { AxiosRequestConfig } from "axios";

function initialState(): Application & { downloadPercentage: number } {
  return {
    id: "",
    author: undefined,
    createdDate: "",
    documents: [],
    item: undefined,
    status: "",
    price: 0,
    number: "",
    history: [],
    operations: [],
    fields: {},
    downloadPercentage: 0,
    isSelectable: false
  };
}

@Module({ namespaced: true })
export default class ApplicationModule extends VuexModule {
  [key: string]: any;
  //
  //  STATE
  //
  id = initialState().id;
  author = initialState().author;
  createdDate = initialState().createdDate;
  documents = initialState().documents;
  item = initialState().item;
  status = initialState().status;
  price = initialState().price;
  number = initialState().number;
  history = initialState().history;
  operations = initialState().operations;
  fields = initialState().fields;
  downloadPercentage = initialState().downloadPercentage;
  //
  //  GETTERS
  //
  get application() {
    return this.context.state;
  }
  //
  //  ACTIONS
  //
  @Action
  public reset(): void {
    this.context.commit("RESET");
  }
  @Action
  public async preload() {
    return await this.context.dispatch(
      "ItemModule/preload",
      {
        fields: this.fields,
        documents: this.documents
      },
      {
        root: true
      }
    );
  }
  @Action
  public async update() {
    return await this.context.dispatch("ItemModule/update", this.id, {
      root: true
    });
  }
  @Action
  public async fetchApplication(number: string) {
    try {
      const params = new URLSearchParams();
      params.append("number", number);
      const config: AxiosRequestConfig = { params };
      const res = await API.get("application", config);
      if (!res) return false;
      else {
        this.context.commit("APPLICATION", res);
        return true;
      }
    } catch {
      return false;
    }
  }
  @Action
  public async changeStatus(payload: { status: string; comment: string }) {
    const res = await API.put(`application/status/change`, {
      status: payload.status,
      comment: payload.comment,
      applicationId: this.id
    });
    if (res) {
      this.context.commit("APPLICATION", res);
      await this.context.dispatch("UserModule/info", null, {
        root: true
      });
      return true;
    } else return false;
  }
  @Action
  public async viewDocument(id: string) {
    const params = new URLSearchParams();
    params.append("documentId", id);
    const config: AxiosRequestConfig = {
      params,
      onDownloadProgress: (progressEvent: any) =>
        this.context.commit("DOWNLOAD_PERCENTAGE", progressEvent)
    };
    return await API.get("document/inline", config);
  }
  //
  //  MUTATIONS
  //
  @Mutation
  public RESET(): void {
    Object.keys(this).forEach((key: string) => {
      this[key] = initialState()[key];
    });
  }
  @Mutation
  public APPLICATION(payload: Application) {
    Object.keys(this).forEach((key: string) => {
      this[key] = payload[key];
    });
  }
  @Mutation
  public DOWNLOAD_PERCENTAGE({
    total,
    loaded
  }: {
    total: number;
    loaded: number;
  }) {
    if (!!loaded && !!total) {
      const res = (100 * loaded) / total;
      this.downloadPercentage = res === 100 || res === 0 ? 0 : res;
    } else this.downloadPercentage = 0;
  }
}
