















import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
const UserModule = namespace("UserModule");
@Component({
  components: {
    Snackbar: () => import("@/components/Snackbar.vue")
  }
})
export default class App extends Vue {
  @UserModule.Getter
  readonly getPermissions!: string[];
  get routerLoading(): boolean {
    return this.$store.state.AppModule.routerLoading;
  }
  get hasPermission(): boolean {
    return (
      this.$route.meta.public ||
      (this.getPermissions.length > 0 &&
        (this.getPermissions.includes("*") ||
          this.getPermissions.includes(this.$route.meta.permission)))
    );
  }
  public onResize() {
    this.$store.commit("AppModule/MENUS", false);
    setImmediate(() => {
      this.$store.commit("AppModule/RESIZE");
      this.$store.commit("AppModule/MENUS", true);
    });
  }
  created() {
    this.onResize();
  }
}
