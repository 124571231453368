// store/modules/app.ts
import { VuexModule, Module, Mutation, Action } from "vuex-module-decorators";
import { AppInt } from "@/types";
import NumberFormat = Intl.NumberFormat;

function initialState(): AppInt {
  return {
    routerLoading: true,
    width: 0,
    height: 0,
    menus: true,
    switchingLanguage: false,
    currencyFormatter: new Intl.NumberFormat("ru-RU", {
      style: "currency",
      currency: "RUB"
    })
  };
}

@Module({ namespaced: true })
export default class AppModule extends VuexModule {
  [key: string]: any;
  //
  //  STATE
  //
  public routerLoading: boolean = initialState().routerLoading;
  public width: number = initialState().width;
  public height: number = initialState().height;
  public menus: boolean = initialState().menus;
  public currencyFormatter: NumberFormat = initialState().currencyFormatter;
  public switchingLanguage: boolean = initialState().switchingLanguage;
  //
  //  GETTERS
  //
  get smallScreen() {
    return this.width <= 599;
  }
  get contentHeight() {
    return this.height - (this.width < 960 ? 56 : 64);
  }
  //
  //  ACTIONS
  //
  @Action
  public reset(): void {
    this.context.commit("RESET");
  }
  @Action
  public router(value: boolean): void {
    this.context.commit("ROUTER_LOADING", value);
  }
  @Action
  public async switchLanguage() {
    if (!this.context.rootState.UserModule.role) return;
    this.context.commit("SWITCHING_LANGUAGE", true);
    const root = { root: true };
    await this.context.dispatch("CategoriesModule/updateItems", null, root);
    await this.context.dispatch(
      "CategoriesModule/updateCategories",
      null,
      root
    );
    await this.context.dispatch("UserModule/fetchApplications", null, root);
    await this.context.dispatch("UserModule/info", null, root);
    this.context.commit("SWITCHING_LANGUAGE", false);
  }
  //
  //  MUTATIONS
  //
  @Mutation
  private RESET(): void {
    Object.keys(this).forEach((key: string) => {
      this[key] = initialState()[key];
    });
  }
  @Mutation
  public ROUTER_LOADING(value: boolean): void {
    this.routerLoading = value;
    this.menus = !value;
  }
  @Mutation
  public RESIZE(): void {
    this.width = window.innerWidth;
    this.height = window.innerHeight;
  }
  @Mutation
  public MENUS(value: boolean): void {
    this.menus = value;
  }
  @Mutation
  public SWITCHING_LANGUAGE(value: boolean): void {
    this.switchingLanguage = value;
  }
}
