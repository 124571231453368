// store/modules/categories.ts
import {
  VuexModule,
  Module,
  Mutation,
  Action,
  config
} from "vuex-module-decorators";
import API from "@/services/api/api";
import { Categories, CategoriesInt, Category, Item, Items } from "@/types";
import i18n from "@/plugins/i18n";

config.rawError = true;

const categoriesEN = [
  {
    name: "Material aid",
    img: "9ede6da5-8484-46c7-9330-c0edd0b87a60",
    id: 0
  },
  {
    name: "Sport",
    img: "20b347a4-9171-466f-b597-622339f90d72",
    id: 1
  },
  {
    name: "Education",
    img: "7ea76fb0-b39f-440b-a7c2-6e7d16225782",
    id: 2
  },
  {
    name: "Medical insurance",
    img: "f87c1356-6998-4597-b5e1-8fd0dfa9efab",
    id: 3
  },
  {
    name: "Financial support",
    img: "eadbf99d-16f9-4f89-9ad8-ded13639da40",
    id: 4
  }
];
const categoriesRU = [
  {
    name: "Материальная помощь",
    img: "9ede6da5-8484-46c7-9330-c0edd0b87a60",
    id: 0
  },
  {
    name: "Спорт",
    img: "20b347a4-9171-466f-b597-622339f90d72",
    id: 1
  },
  {
    name: "Обучение",
    img: "7ea76fb0-b39f-440b-a7c2-6e7d16225782",
    id: 2
  },
  {
    name: "Медицинская страховка",
    img: "f87c1356-6998-4597-b5e1-8fd0dfa9efab",
    id: 3
  },
  {
    name: "Финансовая поддержка",
    img: "eadbf99d-16f9-4f89-9ad8-ded13639da40",
    id: 4
  }
];
function initialState(): CategoriesInt {
  return {
    selected: 0,
    items: [],
    categories: []
  };
}
@Module({ namespaced: true })
export default class CategoriesModule extends VuexModule {
  [key: string]: any;
  //
  //  STATE
  //
  public items = initialState().items;
  public selected = initialState().selected;
  public categories = initialState().categories;
  //
  //  GETTERS
  //
  public get itemsOfSelected() {
    return this.items.filter((item: Item): boolean => {
      return this.selected == item.categoryId;
    });
  }
  public get selectedCategory() {
    return this.categories.find((c: Category): boolean => {
      return c.id == this.selected;
    });
  }
  //
  //  ACTIONS
  //
  @Action
  public async updateItems() {
    this.context.commit("ITEMS", (await API.get("item/list")) || []);
  }
  @Action
  public async updateCategories() {
    if (i18n.locale === "ru") this.context.commit("CATEGORIES", categoriesRU);
    if (i18n.locale === "en") this.context.commit("CATEGORIES", categoriesEN);
  }
  //
  //  MUTATIONS
  //
  @Mutation
  private RESET(): void {
    Object.keys(this).forEach((key: string) => {
      this[key] = initialState()[key];
    });
  }
  @Mutation
  private ITEMS(v: Items): void {
    this.items = v;
  }
  @Mutation
  private CATEGORIES(v: Categories): void {
    this.categories = v;
  }
  @Mutation
  private SELECT(v: number): void {
    this.selected = v;
  }
}
