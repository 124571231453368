// store/modules/item.ts
import { VuexModule, Module, Mutation, Action } from "vuex-module-decorators";
import DocAPI from "@/services/api/DocApi";
import API from "@/services/api/api";
import { Document } from "@/types";
import Vue from "vue";
import { AxiosRequestConfig } from "axios";
import i18n from "@/plugins/i18n";

function initialState(): { [key: string]: any } {
  return {
    current: null,
    documents: [],
    signedTemplate: null,
    signedConsent: null,
    fields: {}
  };
}

@Module({ namespaced: true })
export default class ItemModule extends VuexModule {
  [key: string]: any;
  //
  //  STATE
  //
  private current: number = initialState().current;
  private fields: {} = initialState().fields;
  private documents: Array<Document> = initialState().documents;
  private signedTemplate: Document = initialState().signedTemplate;
  private signedConsent: Document = initialState().signedConsent;
  //
  //  GETTERS
  //
  public get documentsSize() {
    const size = this.documents.reduce((sum, { size }) => sum + size, 0);
    return isNaN(size) ? 0 : size;
  }
  public get application() {
    return {
      item: {
        id: this.current
      },
      fields: this.fields,
      documents: [this.signedTemplate, this.signedConsent, ...this.documents]
        .filter(item => !!item)
        .map(({ id }) => {
          return { id: id };
        })
    };
  }
  //
  //  ACTIONS
  //
  @Action
  public reset(): void {
    this.context.commit("RESET");
  }
  @Action
  public async submit() {
    if (await API.post("application/create", this.application)) {
      this.context.commit("CLEAR_DOCUMENTS");
      this.context.commit("CLEAR_FIELDS");
      this.context.commit("SIGNED_TEMPLATE", null);
      this.context.commit("SIGNED_CONSENT", null);
      localStorage.removeItem("cached-fields");
      return true;
    }
    return false;
  }
  @Action
  public async preload(payload: { fields: {}; documents: Document[] }) {
    this.context.commit("CLEAR_DOCUMENTS");
    this.context.commit("CLEAR_FIELDS");
    this.context.commit("SIGNED_TEMPLATE", null);
    if (payload.fields && Object.keys(payload.fields).length)
      this.context.commit("PRELOAD_FIELDS", payload.fields);
  }
  @Action
  public async update(id: string) {
    if (
      await API.put(`application/update`, {
        id: id,
        documents: this.application.documents,
        fields: this.application.fields
      })
    ) {
      this.context.commit("CLEAR_DOCUMENTS");
      this.context.commit("CLEAR_FIELDS");
      this.context.commit("SIGNED_TEMPLATE", null);
      this.context.commit("SIGNED_CONSENT", null);
      localStorage.removeItem("cached-fields");
      return true;
    }
    return false;
  }
  @Action
  public async uploadDocuments(files: File[]) {
    const formData = new FormData();
    files.forEach(file => formData.append("filenames", file));
    const response: Document = await API.post("document/upload", formData);
    if (response) {
      this.context.commit("ADD_DOCUMENTS", response);
      return true;
    } else {
      return false;
    }
  }
  @Action
  public async uploadTemplate(file: File) {
    const formData = new FormData();
    formData.append("file", file);
    const response: Document = await API.post(
      "document/upload/template",
      formData
    );
    if (response) {
      this.context.commit("SIGNED_TEMPLATE", response);
      return true;
    } else {
      return false;
    }
  }

  @Action
  public async uploadConsent(file: File) {
    const formData = new FormData();
    formData.append("filenames", file);
    const response: Document = await API.post("document/upload", formData);
    if (Array.isArray(response) && response.length === 1) {
      this.context.commit("SIGNED_CONSENT", response[0]);
      return true;
    } else {
      return false;
    }
  }

  @Action
  public async downloadTemplate(body: {
    itemId: number;
    templateName: string;
  }) {
    try {
      const link: any = await DocAPI.post("document/download/template", {
        ...body,
        additionalFields: this.fields
      });
      link.download = body.templateName;
      document.body.appendChild(link);
      link.click();
      return true;
    } catch (e) {
      return false;
    }
  }
  @Action
  public async downloadConsent(itemId: number) {
    try {
      const link: any = await DocAPI.post("document/download/consent", {
        itemId,
        additionalFields: this.fields
      });
      link.download = i18n.t("files.consent");
      document.body.appendChild(link);
      link.click();
      return true;
    } catch (e) {
      return false;
    }
  }
  @Action
  public async deleteDocument(id: string) {
    const params = new URLSearchParams();
    params.append("documentId", id);
    const config: AxiosRequestConfig = { params };
    return await API.delete("document/remove", config);
  }
  //
  //  MUTATIONS
  //
  @Mutation
  private RESET(): void {
    Object.keys(this).forEach((key: string) => {
      this[key] = initialState()[key];
    });
  }
  @Mutation
  public CURRENT(value: number) {
    this.current = value;
  }
  @Mutation
  public ADD_DOCUMENTS(documents: Document[]) {
    documents.forEach(document => {
      this.documents.push(document);
    });
  }
  @Mutation
  public DELETE_DOCUMENT(index: number) {
    this.documents.splice(index, 1);
  }
  @Mutation
  public CLEAR_DOCUMENTS() {
    this.documents = [];
  }
  @Mutation
  public FIELDS(payload: { name: string; value: string | number }) {
    Vue.set(this.fields, payload.name, payload.value);
  }
  @Mutation
  public PRELOAD_FIELDS(payload: {}) {
    this.fields = payload;
  }
  @Mutation
  public CLEAR_FIELDS() {
    this.fields = {};
  }
  @Mutation
  public SIGNED_TEMPLATE(payload: Document) {
    this.signedTemplate = payload;
  }
  @Mutation
  public SIGNED_CONSENT(payload: Document) {
    this.signedConsent = payload;
  }
}
