import Vue from "vue";
import Vuex from "vuex";
import createLogger from "vuex/dist/logger";
import UserModule from "@/store/modules/user";
import AppModule from "@/store/modules/app";
import SnackbarModule from "@/store/modules/snackbar";
import CategoriesModule from "@/store/modules/categories";
import ItemModule from "@/store/modules/item";
import AdminModule from "@/store/modules/admin";
import ApplicationModule from "@/store/modules/application";
import FiltersModule from "@/store/modules/filters";

Vue.use(Vuex);
const debug = process.env.NODE_ENV !== "production";

const modules = {
  AppModule,
  UserModule,
  SnackbarModule,
  ItemModule,
  CategoriesModule,
  AdminModule,
  ApplicationModule,
  FiltersModule
};

const store = new Vuex.Store({
  modules,
  actions: {
    reset({ commit }) {
      // resets state of all the modules
      Object.keys(modules).forEach(moduleName => {
        commit(`${moduleName}/RESET`);
      });
    }
  },
  strict: debug,
  plugins: debug ? [createLogger()] : [] // set logger only for development
});
export default store;
