import i18n from "@/plugins/i18n";
import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import { checkAuthorization, setLang, silent } from "@/services/api/helpers";

const axios = require("axios").default;

const API: AxiosInstance = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL,
  params: new URLSearchParams({ lang: i18n.locale as string })
});

API.interceptors.request.use(async (config: AxiosRequestConfig) => {
  config = setLang(config);
  return checkAuthorization(config);
}, silent);

API.interceptors.response.use(async function(response: AxiosResponse) {
  if (response.status === 200) {
    return response.data;
  } else {
    return undefined;
  }
}, silent);

export default API;
