// store/modules/user.ts
import {
  VuexModule,
  Module,
  Mutation,
  Action,
  config
} from "vuex-module-decorators";
import API from "@/services/api/api";
import { Application, User } from "@/types";

config.rawError = true;

function initialState(): User & { applications: Array<Application> } {
  return {
    familyName: undefined,
    firstName: undefined,
    points: 0,
    role: undefined,
    department: undefined,
    position: undefined,
    email: undefined,
    viewPermissions: [],
    methodPermissions: [],
    applications: [],
    tabel: undefined,
    dateWorkBegin: undefined,
    workLength: undefined
  };
}
@Module({ namespaced: true })
export default class UserModule extends VuexModule {
  [key: string]: any;
  //
  //  STATE
  //
  public familyName = initialState().familyName;
  public firstName = initialState().firstName;
  public points = initialState().points;
  public role = initialState().role;
  public department = initialState().department;
  public position = initialState().position;
  public methodPermissions = initialState().methodPermissions;
  public viewPermissions = initialState().viewPermissions;
  public email = initialState().email;
  public applications = initialState().applications;
  public tabel = initialState().tabel;
  public dateWorkBegin = initialState().dateWorkBegin;
  public workLength = initialState().workLength;
  //
  //  GETTERS
  //
  get user() {
    return this.context.state;
  }
  get getName() {
    let result = "";
    if (this.familyName) result = this.familyName + " ";
    if (this.firstName) result += this.firstName;
    return result;
  }
  get getPoints(): string {
    return this.context.rootState.AppModule.currencyFormatter.format(
      this.points
    );
  }
  get getApplications(): Application[] {
    return this.applications;
  }
  get getRole() {
    return this.role;
  }
  get isLoggedIn() {
    return !!this.role;
  }
  get getPermissions() {
    return this.viewPermissions;
  }
  //
  //  ACTIONS
  //
  @Action
  public async info() {
    const res = await API.get("user/info");
    if (res) {
      this.context.commit("SET", res);
      return true;
    } else return false;
  }
  @Action
  public setUser(user: User) {
    this.context.commit("SET", user);
  }
  @Action
  public async fetchApplications() {
    const res: any = await API.post("application/user/list", {});
    this.context.commit("APPLICATIONS", res.applications);
  }
  @Action
  public async sendFeedback(text: string) {
    return await API.post("service/feedback", { text: text });
  }
  //
  //  MUTATIONS
  //
  @Mutation
  private RESET(): void {
    Object.keys(this).forEach((key: string) => {
      this[key] = initialState()[key];
    });
  }
  @Mutation
  private SET(user: User): void {
    Object.keys(this).forEach((key: string) => {
      if (key !== "applications") this[key] = user[key];
    });
  }
  @Mutation
  private APPLICATIONS(value: Array<Application>): void {
    this.applications = value.sort((a: Application, b: Application): number => {
      return (
        new Date(b.createdDate).getTime() - new Date(a.createdDate).getTime()
      );
    });
  }
}
