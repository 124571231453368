import { AxiosRequestConfig, Canceler } from "axios";
import i18n from "@/plugins/i18n";
import Authentication from "@/services/Authentication";

const axios = require("axios").default;

export function setLang(config: AxiosRequestConfig): AxiosRequestConfig {
  let params: URLSearchParams | undefined = config.params as URLSearchParams;
  if (params) {
    if (params.has("lang")) params.delete("lang");
    params.append("lang", i18n.locale as string);
  } else {
    params = new URLSearchParams({ lang: i18n.locale as string });
  }
  config.params = params;
  return config;
}
export function logging(e: Error) {
  console.log(e);
}
export function silent() {
  return false;
}
export async function checkAuthorization(config: AxiosRequestConfig) {
  const token = await Authentication.check();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
    return config;
  } else {
    return {
      ...config,
      cancelToken: new axios.CancelToken((cancel: Canceler) =>
        cancel("Cancel unauthorized request")
      )
    };
  }
}
