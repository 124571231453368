// store/modules/admin.ts
import { VuexModule, Module, Mutation, Action } from "vuex-module-decorators";
import API from "@/services/api/api";
import { Items, User } from "@/types";
import { AxiosRequestConfig } from "axios";

function initialState(): { [key: string]: any } {
  return {
    items: [],
    users: [],
    filters: {
      dateBegin: null,
      dateEnd: null,
      itemIds: null,
      userIds: null,
      statuses: null,
      orderBy: null,
      orderType: "asc",
      pageNum: 1,
      itemsPerPage: 50
    }
  };
}

@Module({ namespaced: true })
export default class FiltersModule extends VuexModule {
  [key: string]: any;
  //
  //  STATE
  //
  private items: Items = initialState().items;
  private users: User[] = initialState().users;
  private filters = initialState().filters;
  //
  //  GETTERS
  //
  get getFilters() {
    const obj = { ...this.filters };
    Object.keys(obj).forEach(key => {
      const f = obj[key];
      if (!f || (Array.isArray(f) && f.length === 0)) obj[key] = undefined;
    });
    return obj;
  }
  get filtersCount(): number {
    let res = 0;
    res += this.getFilters.dateBegin ? 1 : 0;
    res += this.getFilters.dateEnd ? 1 : 0;
    res += this.getFilters.itemIds ? 1 : 0;
    res += this.getFilters.userIds ? 1 : 0;
    res += this.getFilters.statuses ? 1 : 0;
    return res;
  }
  //
  //  ACTIONS
  //
  @Action
  public reset(): void {
    this.context.commit("RESET");
  }
  @Action
  public async fetchUsers(str: string) {
    const params = new URLSearchParams();
    params.append("query", str);
    const config: AxiosRequestConfig = { params };
    let res: User[] = [];
    try {
      res = await API.get("user/search", config);
      this.context.commit("USERS", res || []);
      return true;
    } catch {
      this.context.commit("USERS", []);
      return false;
    }
  }
  @Action
  public async fetchItems(str: string) {
    const params = new URLSearchParams();
    params.append("query", str);
    const config: AxiosRequestConfig = { params };
    let res: Items = [];
    try {
      res = await API.get("item/search", config);
      if (!res) res = [];
      this.context.commit("ITEMS", res);
      return true;
    } catch {
      this.context.commit("ITEMS", []);
      return false;
    }
  }
  //
  //  MUTATIONS
  //
  @Mutation
  public RESET(): void {
    Object.keys(this).forEach((key: string) => {
      this[key] = initialState()[key];
    });
  }
  @Mutation
  public USERS(payload: User[]) {
    this.users = payload;
  }
  @Mutation
  public ITEMS(payload: Items) {
    this.items = payload;
  }
  @Mutation
  public FILTER_RESET() {
    this.filters.statuses = initialState().filters.statuses;
    this.filters.userIds = initialState().filters.userIds;
    this.filters.itemIds = initialState().filters.itemIds;
    this.filters.dateBegin = initialState().filters.dateBegin;
    this.filters.dateEnd = initialState().filters.dateEnd;
  }
  @Mutation
  public FILTER_STATUS(payload: string[]) {
    this.filters.statuses = payload;
  }
  @Mutation
  public FILTER_ITEM(payload: string[]) {
    this.filters.itemIds = payload;
  }
  @Mutation
  public FILTER_USER(payload: string[]) {
    this.filters.userIds = payload;
  }
  @Mutation
  public FILTER_DATE_BEGIN(payload: string) {
    this.filters.dateBegin = payload;
  }
  @Mutation
  public FILTER_DATE_END(payload: string) {
    this.filters.dateEnd = payload;
  }
  @Mutation
  public FILTER_SORT_BY(payload: string) {
    this.filters.orderBy = payload;
  }
  @Mutation
  public FILTER_SORT_TYPE(payload: string) {
    this.filters.orderType = payload;
  }
  @Mutation
  public FILTER_PAGE(payload: number) {
    this.filters.pageNum = payload;
  }
  @Mutation
  public FILTER_ITEMS_PER_PAGE(payload: number) {
    this.filters.itemsPerPage = payload;
  }
}
