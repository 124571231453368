import Vue from "vue";
import VueRouter from "vue-router";
import * as functions from "./functions";
import routes from "./routes";
import store from "../store";

Vue.use(VueRouter);

const router = new VueRouter({
  routes,
  mode: "history",
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  }
});

router.beforeEach(async (to, from, next) => {
  await store.dispatch("AppModule/router", true);
  functions.updatePageTitleAndMeta(document, to, next);
  const route: any = await functions.handleUnauthorizedAccess(from, to);
  if (route.name) {
    next(route);
    await store.dispatch("AppModule/router", false);
  } else next();
});
router.afterEach(async () => {
  await store.dispatch("AppModule/router", false);
});
export default router;
