import Settings from "@/assets/Settings.vue";

import Vue from "vue";
import Vuetify from "vuetify/lib";
import minifyTheme from "minify-css-string";
import ru from "vuetify/es5/locale/ru";
import en from "vuetify/lib/locale/en";
import { Ripple } from "vuetify/lib/directives";

Vue.use(Vuetify, {
  directives: {
    Ripple
  }
});

export default new Vuetify({
  lang: {
    current: process.env.VUE_APP_I18N_LOCALE || process.env.VUE_APP_I18N_LOCAL,
    locales: { ru, en }
  },
  theme: {
    options: {
      minifyTheme,
      variations: false,
      customProperties: true,
      themeCache: {
        get: () =>
          localStorage.getItem(
            `${process.env.VUE_APP_NAME}-${process.env.VUE_APP_VERSION}`
          ),
        set: (_, value) =>
          localStorage.setItem(
            `${process.env.VUE_APP_NAME}-${process.env.VUE_APP_VERSION}`,
            value
          )
      }
    },
    dark: false,
    themes: {
      light: {
        primary: "#B91716",
        secondary: "#ebeced",
        accent: "#B91716",
        error: "#E28413",
        info: "#00A5CF",
        success: "#226F54",
        warning: "#E28413"
      }
    }
  },
  icons: {
    iconfont: "mdiSvg",
    values: {
      settings: {
        component: Settings
      }
    }
  }
});
