import storage from "@/services/web-storage";
import axios from "axios";
import store from "../store";
import router from "@/router";
import { UserCredentials } from "@/types";

const API_URL = process.env.VUE_APP_API_BASE_URL + "auth/";

class Authentication {
  refreshingToken = false;
  readonly uid: string;
  constructor() {
    this.uid = this.generateDeviceId();
  }
  generateDeviceId() {
    const navigatorInfo = window.navigator;
    const screenInfo = window.screen;
    let uid = navigatorInfo.userAgent.replace(/\D+/g, "");
    uid += screenInfo.height || "";
    uid += screenInfo.width || "";
    uid += screenInfo.pixelDepth || "";
    return uid;
  }

  async check(): Promise<string | undefined> {
    if (this.refreshingToken) {
      return new Promise(resolve => {
        setTimeout(async () => {
          resolve(await this.check());
        }, 1);
      });
    }
    if (await this.isAccessValid()) return storage.getAccessToken();
    else return;
  }
  async isAccessValid() {
    return (
      (storage.getAccessToken() &&
        storage.getAccessExpiry() &&
        Date.now() < storage.getAccessExpiry()) ||
      (await this.refresh())
    );
  }
  async refresh() {
    if (this.isRefreshValid()) {
      this.refreshingToken = true;
      const res = await this.authentication({
        url: "refresh",
        config: {
          headers: {
            Authorization: `Bearer ${storage.getRefreshToken()}`
          },
          params: {
            deviceId: this.uid
          }
        }
      });
      setTimeout(() => (this.refreshingToken = false));
      return res;
    } else return false;
  }
  isRefreshValid() {
    return (
      storage.getRefreshToken() &&
      storage.getRefreshExpiry() &&
      Date.now() < storage.getRefreshExpiry()
    );
  }
  async authentication({
    url,
    data,
    config
  }: {
    url: string;
    data?: object;
    config?: object;
  }) {
    try {
      const res = await axios.post(API_URL + url, data, config);
      if (res.status === 200) {
        await this.onLoginSuccess(res.data);
        return true;
      } else {
        return false;
      }
    } catch (e) {
      return false;
    }
  }
  async onLoginSuccess(data: any) {
    await store.dispatch("UserModule/setUser", data);

    if (data.accessToken) storage.setAccessToken(data.accessToken);

    if (data.accessTokenExpiresIn) {
      const t = Date.now() + data.accessTokenExpiresIn * 1000 - 5000;
      storage.setAccessTokenExpiry(t);
    }
    if (data.refreshToken) storage.setRefreshToken(data.refreshToken);

    if (data.refreshTokenExpiresIn) {
      const t = Date.now() + data.refreshTokenExpiresIn * 1000;
      storage.setRefreshTokenExpiry(t);
    }
  }

  async login({ email, code }: UserCredentials) {
    return await this.authentication({
      url: "login/email",
      data: { email, code, deviceId: this.uid }
    });
  }

  async code(email: string) {
    try {
      const response = await axios.post(API_URL + "code", undefined, {
        params: new URLSearchParams({
          email: email,
          deviceId: this.uid
        })
      });
      return response?.status === 200;
    } catch (e) {
      return false;
    }
  }

  async logout() {
    const token = storage.getAccessToken();
    if (token)
      await axios.post(API_URL + "logout", undefined, {
        headers: { Authorization: `Bearer ${token}` }
      }); //server
    await storage.clear(); //localstore
    await store.dispatch("reset", undefined, { root: true }); //vuex
    setTimeout(() => {
      router.push("/auth");
    }, 0);
  }
}

export default new Authentication();
