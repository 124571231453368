// store/modules/snackbar.ts
import { VuexModule, Module, Mutation, Action } from "vuex-module-decorators";
import i18n from "@/plugins/i18n";
import { SnackbarInt } from "@/types";

function initialState(): SnackbarInt {
  return {
    status: false,
    color: "error",
    message: ""
  };
}

@Module({ namespaced: true })
export default class SnackbarModule extends VuexModule {
  [key: string]: any;
  //
  //  STATE
  //
  public status: boolean = initialState().status;
  public color: string = initialState().color;
  public message: string = initialState().message;
  //
  //  ACTIONS
  //
  @Action
  public reset(): void {
    this.context.commit("RESET");
  }
  @Action
  public set(message?: string) {
    this.context.dispatch("openSnackbar", {
      color: "error",
      message: message
        ? message
        : (i18n.t("snackbar.message-default") as string)
    });
  }
  @Action
  public openSnackbar({
    color,
    message
  }: {
    color: string;
    message: string;
  }): void {
    this.context.commit("SET_SNACKBAR", false);
    this.context.commit("SET_SNACKBAR_MESSAGE", message);
    this.context.commit("SET_SNACKBAR_COLOR", color);
    setTimeout(() => {
      this.context.commit("SET_SNACKBAR", true);
    }, 10);
  }
  //
  //  MUTATIONS
  //
  @Mutation
  private RESET(): void {
    Object.keys(this).forEach((key: string) => {
      this[key] = initialState()[key];
    });
  }
  @Mutation
  public SET_SNACKBAR(value: boolean) {
    this.status = value;
  }
  @Mutation
  public SET_SNACKBAR_MESSAGE(value: string) {
    this.message = value;
  }
  @Mutation
  public SET_SNACKBAR_COLOR(value: string) {
    this.color = value;
  }
}
