import store from "../store";
import { NavigationGuardNext, RawLocation, Route } from "vue-router";
import i18n from "@/plugins/i18n";

export function updatePageTitleAndMeta(
  document: Document,
  to: Route,
  next: NavigationGuardNext
): void {
  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find(r => r.meta && r.meta.title);
  const nearestWithMeta = to.matched
    .slice()
    .reverse()
    .find(r => r.meta && r.meta.metaTags);
  if (nearestWithTitle)
    document.title = i18n.t(nearestWithTitle.meta.title) as string;
  Array.from(
    document.querySelectorAll("[data-vue-router-controlled]")
  ).map(el => el.parentNode?.removeChild(el));
  if (!nearestWithMeta) return next();
  nearestWithMeta.meta.metaTags
    .map((tagDef: { [x: string]: string }) => {
      const tag: HTMLMetaElement = document.createElement("meta");
      Object.keys(tagDef).forEach(key => {
        tag.setAttribute(key, tagDef[key]);
      });
      tag.setAttribute("data-vue-router-controlled", "");
      return tag;
    })
    .forEach((tag: HTMLMetaElement) => document.head.appendChild(tag));
  next();
}
function defaultPage(): RawLocation {
  const role = store.getters["UserModule/getRole"];
  let name = "Categories";
  if (role === "admin") name = "Admin";
  return {
    name: name
  };
}
export async function handleUnauthorizedAccess(from: Route, to: Route) {
  const isLoggedIn: boolean =
    store.getters["UserModule/isLoggedIn"] ||
    (await store.dispatch("UserModule/info"));
  const isNavToAuth: boolean = to.path === "/auth";
  if (to.meta.public) {
    if (isNavToAuth && isLoggedIn) return defaultPage();
    else return true;
  } else {
    if (!isLoggedIn) return { name: "Auth" };
    else {
      const permissions: string[] = store.getters["UserModule/getPermissions"];
      if (permissions.includes("*") || permissions.includes(to.meta.permission))
        return true;
      else return defaultPage();
    }
  }
}
