import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import { checkAuthorization, setLang, silent } from "@/services/api/helpers";
import i18n from "@/plugins/i18n";

const axios = require("axios").default;

const DocAPI: AxiosInstance = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL,
  params: new URLSearchParams({ lang: i18n.locale as string })
});

DocAPI.interceptors.request.use(async (config: AxiosRequestConfig) => {
  config = setLang(config);
  config.responseType = "blob";
  return checkAuthorization(config);
}, silent);

DocAPI.interceptors.response.use(function(
  response: AxiosResponse<BlobPart>
): any {
  if (response.status === 200) {
    const bp: BlobPart = response.data;
    const blob = new Blob([bp], {
      type: response.headers["content-type"]
    });
    const link: HTMLElementTagNameMap["a"] = document.createElement("a");
    const URL = window.URL || window.webkitURL;
    const downloadUrl = URL.createObjectURL(blob);
    link.href = downloadUrl;
    link.style.display = "none";
    setTimeout(function() {
      document.body.removeChild(link);
      window.URL.revokeObjectURL(downloadUrl);
    }, 100);
    return link;
  } else {
    return undefined;
  }
},
silent);

export default DocAPI;
